//CORE
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";

//ICONS
import { MenuOutlined } from "@ant-design/icons";

//CUSTOM
import { TopBarWrapper } from "./TopBar.style";
import { hideSidebar, showSidebar } from "CustomRedux/App/app-slice";
import TopBarUser from "./TopBarUser";

const { Header } = Layout;

const TopBar = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userInfo);
  const collapsed = useSelector((state) => state.app.collapsed);

  const toggleCollapsed = () => {
    if (collapsed) {
      dispatch(showSidebar());
    } else {
      dispatch(hideSidebar());
    }
  };

  return (
    <TopBarWrapper>
      <Header className={`isomorphicTopbar ${collapsed && "collapsed"}`}>
        <div className="isoLeft">
          <button
            className={
              collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
            }
            onClick={toggleCollapsed}
          >
            <MenuOutlined className="menu-icon" />
          </button>
        </div>
        <ul className="isoRight">
          {userData.name && <li className="user-name">{userData.name}</li>}
          <li className="isoUser">
            <TopBarUser />
          </li>
        </ul>
      </Header>
    </TopBarWrapper>
  );
};

export default TopBar;
