//CORE
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

//STYLE
import 'react-quill/dist/quill.snow.css';

//CUSTOM
import { AntComponentTheme } from "./themeSetting";
import { UtilityStyles } from "Styles/Utils";
import { store } from "./CustomRedux/store";
import Website from "Components/Website";

const App = () => {
  return (
    <Provider store={store}>
      <ConfigProvider theme={AntComponentTheme}>
        <BrowserRouter>
          <UtilityStyles />
          <Website />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
};

export default App;
