//CORE
import { COLORS } from "Styles/Constants";
import { Component } from "react";

//ICONS
import { MdErrorOutline } from "react-icons/md";

export default class PageErrorBoundary extends Component{
  constructor(props) {
    super(props);

    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    console.error("page errorBoundary caught an error: ", error);
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div
          style={{ height: "100%" }}
          className="flex f-column f-v-center f-h-center"
        >
          <MdErrorOutline style={{ fontSize: 100, color: COLORS.ERROR }} />
          <p style={{ color: COLORS.ERROR, fontSize: 24, fontWeight: 600 }}>
            Something went wrong
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
