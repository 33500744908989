import { Spin } from "antd";
import React from "react";

const Loader = ({ loadingText = "", spinning = true }) => {
  return (
    <div
      className="flex f-v-center f-h-center"
      style={{ width: "100%", height: "100%" }}
    >
      <Spin spinning={spinning}></Spin>
    </div>
  );
};

export default Loader;
