import { createSlice } from "@reduxjs/toolkit";

const storedUserInfo = localStorage.getItem("userInfo");
const userInfo = storedUserInfo ? JSON.parse(storedUserInfo) : {};

const loggedInStatus = localStorage.getItem("isLoggedInCMS");

const initialState = {
  isLoggedIn: loggedInStatus ? JSON.parse(loggedInStatus) : false,
  userInfo,
  accessToken: localStorage.getItem("accessToken"),
  language: {
    languageId: 1,
    languageCode: "en",
  },
};

const counterSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    //LOGIN
    login(state, action) {
      localStorage.setItem("authToken", action.payload.accessToken);
      localStorage.setItem("isLoggedInCMS", true);
      localStorage.setItem("userId", action.payload.userInfo?.id);
      delete action.payload.userInfo.accessToken;
      localStorage.setItem("userInfo", JSON.stringify(action.payload.userInfo));
      state.userInfo = action.payload.userInfo;
      state.accessToken = action.payload.accessToken;
      state.isLoggedIn = true;
    },
    //LOGOUT
    logout(state) {
      state.isLoggedIn = false;
      state.userInfo = {};
      state.accessToken = null;
      localStorage.clear();
    },
    //UPDATE USER INFO
    updateUserInfo(state, action){
      localStorage.setItem("userInfo", JSON.stringify(action.payload.userInfo));
      state.userInfo = action.payload.userInfo
    }
  },
});

export const { login, logout, updateUserInfo } = counterSlice.actions;
export default counterSlice.reducer;
