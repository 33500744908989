//CORE
import React, { useEffect } from "react";
import { Routes, Route as ReactRoute, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//CUSTOM
import {
  URL_DASHBOARD,
  URL_FIRST_TIME_CHANGE_PASSWORD,
  URL_FORGOT_PASSWORD,
  URL_LOGIN,
  URL_PRIVACY_POLICY,
  URL_RESET_PASSWORD,
  URL_SIGN_IN,
  URL_TERMS_AND_CONDITIONS,
} from "Helpers/Paths";
import RoutesList from "./RouteList";
import ProtectedRoute from "./ProtectedRoute";
import Loader from "Components/Common/Loader/Loader";

const Login = React.lazy(() => import("Components/Pages/Login/Login"));
const ForgotPassword = React.lazy(() =>
  import("Components/Pages/ForgotPassword/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("Components/Pages/ResetPassword/ResetPassword")
);
const PageNotFound = React.lazy(() =>
  import("Components/Pages/404/PageNotFound")
);
const StaticPageUI = React.lazy(() =>
  import("Components/Pages/StaticPageUI/StaticPageUI")
);

const BEFORE_LOGIN_ACCESSIBLE_PATHS = [
  URL_LOGIN,
  URL_SIGN_IN,
  URL_FORGOT_PASSWORD,
  URL_RESET_PASSWORD,
];

const Route = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userInfo = useSelector((state) => state.auth.userInfo);

  useEffect(() => {
    if(isLoggedIn && userInfo.first_time_login){
      navigate({ pathname: URL_FIRST_TIME_CHANGE_PASSWORD });
    }
    
    if (
      isLoggedIn &&
      BEFORE_LOGIN_ACCESSIBLE_PATHS.includes(window?.location?.pathname)
    ) {
      navigate({ pathname: URL_DASHBOARD });
    }

  }, [isLoggedIn, navigate, userInfo.first_time_login]);

  return (
    <Routes>
      <ReactRoute
        path={URL_SIGN_IN}
        element={
          <React.Suspense fallback={<Loader />}>
            <Login />
          </React.Suspense>
        }
      />
      <ReactRoute
        path={URL_LOGIN}
        element={
          <React.Suspense fallback={<Loader />}>
            <Login />
          </React.Suspense>
        }
      />
      <ReactRoute
        path={URL_FORGOT_PASSWORD}
        element={
          <React.Suspense fallback={<Loader />}>
            <ForgotPassword />
          </React.Suspense>
        }
      />
      <ReactRoute
        path={URL_RESET_PASSWORD}
        element={
          <React.Suspense fallback={<Loader />}>
            <ResetPassword />
          </React.Suspense>
        }
      />
      <ReactRoute
        path={URL_TERMS_AND_CONDITIONS}
        element={
          <React.Suspense fallback={<Loader />}>
            <StaticPageUI />
          </React.Suspense>
        }
      />
      <ReactRoute
        path={URL_PRIVACY_POLICY}
        element={
          <React.Suspense fallback={<Loader />}>
            <StaticPageUI />
          </React.Suspense>
        }
      />
      {RoutesList.map((route, index) => (
        <React.Fragment key={index}>
          {route.hasChildren ? (
            <ReactRoute
              path={route.path}
              element={<ProtectedRoute>{route.Component}</ProtectedRoute>}
            >
              {route.children}
            </ReactRoute>
          ) : (
            <ReactRoute
              path={route.path}
              element={<ProtectedRoute>{route.Component}</ProtectedRoute>}
            />
          )}
        </React.Fragment>
      ))}
      <ReactRoute
        path={"*"}
        element={
          <React.Suspense fallback={<Loader />}>
            <PageNotFound />
          </React.Suspense>
        }
      />
    </Routes>
  );
};

export default Route;
