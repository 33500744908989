//CORE
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

//ERROR BOUNDARY HANDLER
import PageErrorBoundary from "Error/PageErrorBoundary";

//CUSTOM
import { URL_LOGIN } from "Helpers/Paths";
import React from "react";
import Loader from "Components/Common/Loader/Loader";

const ProtectedRoute = (props) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <div style={{ height: "inherit" }}>
      {!isLoggedIn ? (
        <Navigate replace to={URL_LOGIN} />
      ) : (
        <React.Fragment>
          <PageErrorBoundary>
            <React.Suspense fallback={<Loader />}>
              {props.children}
            </React.Suspense>
          </PageErrorBoundary>
        </React.Fragment>
      )}
    </div>
  );
};

export default ProtectedRoute;
