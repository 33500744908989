//CORE
import { useState } from "react";
import { Typography } from "antd";

//ICON
import { IoLogOutOutline } from "react-icons/io5";

//IMG
// import userPic from "Assets/Image/DemoPic/user1.png";
// import { TopBarDropdownWrapper } from "./TopBarUser.style";
import LogoutModal from "./Modal/LogoutModal";

const TopBarUser = () => {
  // const [visible, setVisible] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  // const handleVisibleChange = () => {
  //   setVisible((prev) => !prev);
  // };

  // const handleOpenModal = () => {
  //   setLogoutModal(true);
  //   handleVisibleChange();
  // };

  // const content = (
  //   <TopBarDropdownWrapper className="isoUserDropdown">
  //     <div className="isoDropdownLink" onClick={handleOpenModal}>
  //       <i className="ion-android-exit nav-icon" />
  //       <span>Logout</span>
  //     </div>
  //   </TopBarDropdownWrapper>
  // );

  return (
    <>
      {/* <Popover
        content={content}
        trigger="click"
        open={visible}
        onOpenChange={handleVisibleChange}
        arrow={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <img alt="user" src={userPic} />
          <span className="userActivity online" />
        </div>
      </Popover> */}
      <div
        className="flex f-v-center hover logout-wrapper"
        onClick={() => setLogoutModal(true)}
      >
        <Typography className="logout-text">Logout</Typography>
        <IoLogOutOutline className="logout-icon" />
      </div>
      {logoutModal && <LogoutModal closeModal={() => setLogoutModal(false)} />}
    </>
  );
};

export default TopBarUser;
