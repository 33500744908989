//CORE
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";

//CUSTOM
import { SidebarWrapper } from "./SideBar.style";
import { activeSidebar, toggleDrawer } from "CustomRedux/App/app-slice";
import MENU_ITEM from "Helpers/Options";
import { ADMIN_TYPE, DASHBOARD_KEY } from "Helpers/Constants";
import Logo from "./Logo";
import { useCallback, useEffect, useState } from "react";

const { Sider } = Layout;

const SideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const collapsed = useSelector((state) => state.app.collapsed);
  const openDrawer = useSelector((state) => state.app.openDrawer);
  const drawerCurrent = useSelector((state) => state.app.current);
  const adminInfo = useSelector((state) => state.auth.userInfo);
  const DrawerCollapsed = collapsed && !openDrawer;
  const mode = DrawerCollapsed === true ? "vertical" : "inline";

  const [menuItem, setMenuItem] = useState([]);

  const onMouseEnter = () => {
    if (openDrawer === false) {
      dispatch(toggleDrawer());
    }
    return;
  };

  const onMouseLeave = () => {
    if (openDrawer === true) {
      dispatch(toggleDrawer());
    }
    return;
  };

  const handleMenu = (e) => {
    dispatch(activeSidebar([e.key]));
    navigate({ pathname: e.key });
  };

  const handleModuleAccess = useCallback(() => {
    if (adminInfo.role === ADMIN_TYPE.ADMIN) {
      setMenuItem(MENU_ITEM);
    } else if (adminInfo.role === ADMIN_TYPE.SUB_ADMIN) {
      const adminMenuItemsList = [];
      MENU_ITEM.forEach((item) => {
        if (item.children === null && item.userType === ADMIN_TYPE.SUB_ADMIN)
          adminMenuItemsList.push(item);

        if (item.children !== null && item.userType === ADMIN_TYPE.SUB_ADMIN) {
          const children = item.children.filter(
            (child) => child.userType === ADMIN_TYPE.SUB_ADMIN
          );
          adminMenuItemsList.push({ ...item, children });
        }
      });
      setMenuItem(adminMenuItemsList);
    }
  }, [adminInfo]);

  useEffect(() => {
    handleModuleAccess();
  }, [handleModuleAccess]);

  return (
    <SidebarWrapper>
      <Sider
        trigger={null}
        collapsible={true}
        width={240}
        collapsed={DrawerCollapsed}
        className="isomorphicSidebar"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Logo collapsed={DrawerCollapsed} />
        <div className="options">
          <Menu
            className="isoDashboardMenu"
            theme={"dark"}
            mode={mode}
            onClick={handleMenu}
            defaultOpenKeys={[DASHBOARD_KEY]}
            selectedKeys={drawerCurrent}
            items={menuItem}
          ></Menu>
        </div>
      </Sider>
    </SidebarWrapper>
  );
};

export default SideBar;
