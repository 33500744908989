//CORE
import { useSelector } from "react-redux";
import { Layout } from "antd";

//CUSTOM
import { ContentWrapper, WebsiteWrapper } from "./Website.style";
import { Sidebar } from "./Common/SideBar";
import { TopBar } from "./Common/TopBar";
import { SITE_CONFIG } from "Helpers/Constants";
import Route from "Routes/Route";

const { Footer } = Layout;

const Website = () => {
  //   const isLoggedIn = true;
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userInfo = useSelector((state) => state.auth.userInfo);

  return (
    <WebsiteWrapper>
      <Layout>
        <Layout>
          {isLoggedIn && !userInfo.first_time_login && <Sidebar />}
          <Layout className="content-layout">
            {isLoggedIn && !userInfo.first_time_login && <TopBar />}
            <ContentWrapper
              $ShowSideBar={isLoggedIn}
              $firstTimeLogin={userInfo.first_time_login}
              $mobileHeight={138}
            >
              <Route />
            </ContentWrapper>
            {isLoggedIn && !userInfo.first_time_login && (
              <Footer className="footer-style">{SITE_CONFIG.footerText}</Footer>
            )}
          </Layout>
        </Layout>
      </Layout>
    </WebsiteWrapper>
  );
};

export default Website;
