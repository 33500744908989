import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./Auth/auth-slice";
import appReducer from "./App/app-slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    app: appReducer,
  },
});
