import { createSlice } from "@reduxjs/toolkit";
import { DASHBOARD_KEY } from "Helpers/Constants";
import { getSideBarCurrentTab } from "Helpers/Utils";

const initialState = {
  collapsed: window.innerWidth > 1220 ? false : true,
  openDrawer: false,
  current: !!getSideBarCurrentTab().length
    ? getSideBarCurrentTab()
    : [DASHBOARD_KEY],
};

const counterSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    hideSidebar(state) {
      state.collapsed = true;
    },
    showSidebar(state) {
      state.collapsed = false;
    },
    toggleSidebar(state) {
      state.collapsed = !state.collapsed;
    },
    toggleDrawer(state) {
      state.openDrawer = !state.openDrawer;
    },
    activeSidebar(state, action) {
      state.current = action.payload;
    },
  },
});

export const {
  hideSidebar,
  showSidebar,
  toggleSidebar,
  toggleDrawer,
  activeSidebar,
} = counterSlice.actions;
export default counterSlice.reducer;
