// import { COLORS } from "Styles/Constants";

export const AntComponentTheme = {
  token: {},
  components: {
    Button: {
      borderRadius: 4,
      // fontSize: 18,
      // boxShadow: 'none'
    },
    Select: {
      borderRadius: 4,
      borderRadiusLG: 4,
      borderRadiusSM: 4,
    },
    Modal: {
      borderRadiusLG: 4,
      borderRadiusSM: 4,
      wireframe: true,
    },
    Input: {
      borderRadius: 4,
      borderRadiusLG: 4,
      borderRadiusSM: 4,
    },
    DatePicker: {
      borderRadius: 4,
      borderRadiusLG: 4,
      borderRadiusSM: 4,
    },
    Menu: {
      iconSize: 18,
      collapsedIconSize: 18,
    },
  },
};
